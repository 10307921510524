@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

$dark-color: #212020;
$main-color: #217131;
$secondary-color: #11481d;
$icon-color: #030c05;
$scrollbar-color: #808080;
$background-question-color: #5c4b4b;

.one-question {
    background-color: $dark-color;
    border-radius: 7px;
    width: 800px;
    margin-bottom: 20px;
    padding: 10px;
    height: 75px;
    overflow: hidden;
    transition: height 1s;
}

.one-question section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.one-question h2 {
    font-weight: 400;
    letter-spacing: 1px;
    margin-left: 15px;
    font-size: 20px;
    height: 50px;
    font-family: 'Nunito', sans-serif;
}

.one-question button {
    height: 40px;
    margin-right: 15px;
    margin-left: 10px;
    background: $main-color;
    outline: none;
    border: none;
    border-radius: 7px;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.one-question button:hover {
    background: $secondary-color;
}

.one-question .my-icon {
    font-size: 20px;
    color: $icon-color;
}

.one-question p {
    width: 90%;
    margin: 0px 15px 10px;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 2px solid $background-question-color;
    color: $main-color;
    font-size: 22px;
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1px;
}

.one-question.show {
    height: 150px;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
  }

  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $scrollbar-color;
    border-radius: 10px;
  }

 
  ::-webkit-scrollbar-thumb {
    background: $main-color;
    border-radius: 10px;
  }

  .btn {
    letter-spacing: 1px;
  }


/* Responsibilita */
@media screen and (max-width: 890px) {
    .one-question {
        width: auto;
    }

    .one-question h2 {
        font-size: 15px;
    }

    .one-question button {
        font-size: 12px;
    }
}

@media screen and (max-width: 750px) {
    .one-question h2 {
        display: flex;
    }

    .one-question button {
        font-size: 10px;
    }

    .one-question p {
        font-size: 15px;
        width: 85%;
    }

    .one-question.show {
        height: 130px;
    }
}

@media screen and (max-width: 480px) {
    .one-question p {
        width: 80%;
    }
}

@media screen and (max-width: 420px) {
    .one-question p {
        width: 77%;
    }

    .one-question.show {
        height: 120px;
    }
}

@media screen and (max-width: 400px) {
    .one-question h2 {
        font-size: 12px;
    }

    .one-question button {
        font-size: 12px;
    }

    .one-question p {
        font-size: 12px;
        width: 75%;
    }
}

@media screen and (max-width: 300px) {
    .one-question p {
        width: 70%;
    }
}