@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

body {
    background-color: #151515;
    margin: 0px;
}

#result {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    align-items: center;
    margin-top: 25px;
}

.all-questions {
    color: #c9fae1;
}

.all-questions h1 {
    font-size: 80px;
    color: #217131;
    letter-spacing: 1px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    margin: 0px 5px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header input {
    width: 500px;
    margin-top: 25px;
    background-color: #212020;
    border: none;
    color: #c9fae1;
    outline: none;
    font-size: 20px;
    letter-spacing: 1px;
    padding: 5px 10px;
    border-radius: 7px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.reset-btn {
    color: #030c05;
    background: #217131;
    padding: 10px 15px;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 18px;
    outline: none;
    border: none;
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-btn:hover {
    background: #11481d;
}

.reset-btn .reload-icon {
    font-size: 20px;
}

.all-questions-targets {
    margin-top: 50px;
}

.contact {
    background-color: #212020;
    width: 100%;
    border-radius: 7px 7px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact a {
  margin: 5px 10px;
  color: #42b37a;
  cursor: pointer;
  font-size: 30px;
}

.contact a:hover {
    color: #11481d;
}

@media screen and (max-width: 750px) {
    .all-questions {
        margin: 0px 10px;
    }

    .all-questions h1 {
        font-size: 60px;
    }

    .header input {
        width: 400px;
    }
}

@media screen and (max-width: 500px) {
    .all-questions h1 {
        font-size: 50px;
    }

    .header input {
        width: 300px;
    }
}

@media screen and (max-width: 400px) {
    .all-questions h1 {
        font-size: 40px;
    }

    .header input {
        width: 250px;
    }
}

@media screen and (max-width: 320px) {
    .all-questions h1 {
        font-size: 30px;
    }

    .header input {
        width: 200px;
    }
}